import React, { useState } from "react";
import { addFromValues } from "../../CurrentChargeTab/Tabs/Rate";
import { useMutation } from "@apollo/client";
import { GET_TRUSTEES_REQUESTS, REVIEW_MRD_REQUEST } from "../../../Queries";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const _ = require("lodash");

const OverviewRate = ({ mode, status }: any) => {
  const filterdArray = addFromValues(
    _.sortBy(mode?.range_charge, (item: any) =>
      item.upto === null ? Infinity : item.upto
    )
  );

  return filterdArray.map((d: any, i: number) => {
    return (
      <div
        className={
          "grid gap-x-2 " +
          (status === "APPROVED"
            ? " grid-cols-6"
            : status === "REJECTED"
            ? " grid-cols-6"
            : " grid-cols-5")
        }
        key={i}
      >
        <div>
          <p className=" text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} <br /> (
            <span className="text-[8px]">{mode?.payment_mode}</span>)
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.upto
              ? `${d?.from > 0 ? d?.from : "<="} ${
                  d?.upto >= d?.from && d?.from !== 0 ? "-" + d?.upto : d?.upto
                }`
              : `>=${d?.from}`}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.charge_type === "FLAT"
              ? "₹" + d?.base_charge
              : d?.base_charge + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">{d?.commission}</p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">{d?.charge}</p>
        </div>
        {status === "APPROVED" ? (
          <div>
            <p className=" text-[#1B163B] text-xs font-normal">{d?.charge}</p>
          </div>
        ) : status === "REJECTED" ? (
          <div></div>
        ) : (
          " "
        )}
      </div>
    );
  });
};
const StatusButton = ({
  comment,
  requestId,
  status,
  schoolId,
  trusteeId,
  setOpen,
}: any) => {
  const [reviewMdrRequest, { loading }] = useMutation(REVIEW_MRD_REQUEST, {
    refetchQueries: [
      {
        query: GET_TRUSTEES_REQUESTS,
        variables: { trusteeId },
      },
    ],
  });
  return (
    <button
      onClick={async () => {
        try {
          const res = await reviewMdrRequest({
            variables: {
              requestId: requestId,
              status: status,
              comment: comment || "NA",
              school_id: schoolId,
            },
          });
          setOpen(false);
          toast.success(`${res.data?.reviewMdrRequest}`);
        } catch (err) {
          toast.error(`${err}`);
          setOpen(false);
        }
      }}
      className={
        " px-4 py-2 h-10 w-32 text-center rounded-md capitalize text-white " +
        (status === "APPROVED"
          ? "bg-[#229635] disabled:bg-[#2296358c]"
          : " bg-[#F92D00] disabled:bg-[#f92e007c]")
      }
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="mx-auto animate-spin text-xl font-extrabold text-white" />
      ) : (
        status?.toLowerCase()
      )}
    </button>
  );
};

function OverviewTab({
  data,
  status,
  requestId,
  schoolId,
  trusteeId,
  setOpen,
}: any) {
  const [comment, setComment] = useState("");

  return (
    <div className="px-6 py-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
      <textarea
        onChange={(e) => {
          setComment(e.target.value);
        }}
        placeholder="enter comment"
        name="comment"
        className="h-28 focus:outline-none w-full max-w-md p-4 rounded-md"
      ></textarea>
      <div className="flex  items-center gap-x-3">
        <button className="bg-[#6687FF] px-4 py-2 rounded-md text-white">
          Send Mail
        </button>
        <StatusButton
          requestId={requestId}
          comment={comment}
          status={"APPROVED"}
          schoolId={schoolId}
          trusteeId={trusteeId}
          setOpen={setOpen}
        />
        <StatusButton
          requestId={requestId}
          comment={comment}
          setOpen={setOpen}
          status={"REJECTED"}
          schoolId={schoolId}
          trusteeId={trusteeId}
        />
      </div>

      <div
        className={
          "grid  " +
          (status === "APPROVED"
            ? " grid-cols-6"
            : status === "REJECTED"
            ? " grid-cols-6"
            : " grid-cols-5")
        }
      >
        <div>
          <p className=" text-[#777777] text-xs font-normal">Payment Mode</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">Range</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">Base Rates</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">
            My Commission <br /> ( Requested )
          </p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">
            Requested Rate <br /> ( Total )
          </p>
        </div>
        {status === "APPROVED" ? (
          <div>
            <p className=" text-[#777777] text-xs font-normal">
              Final Rate (Applied )
            </p>
          </div>
        ) : status === "REJECTED" ? (
          <div>
            <p className=" text-[#777777] text-xs font-normal">
              Final Rate (Applied )
            </p>
          </div>
        ) : (
          " "
        )}
      </div>
      {data?.map((mode: any, i: any) => {
        return <OverviewRate key={i} status={status} mode={mode} />;
      })}
    </div>
  );
}

export default OverviewTab;
