/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MdArrowBackIos } from "react-icons/md";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ToolTip from "../../../../../components/ToolTip";
import { GET_SCHOOL_GATEWAY_RATIO } from "../Queries";
import { ADD_GATEWAY_RATIO } from "../../ManagePlatformCharges/Queries";

function SetSchoolGatwayRate({ setOpen, schoolId, trusteeId }: any) {
  const {
    data: school_gateway_ratio,
    refetch,
    loading: gateway_ratio_loading,
  } = useQuery(GET_SCHOOL_GATEWAY_RATIO, {
    variables: {
      school_id: schoolId,
    },
  });

  useEffect(() => {
    refetch();
  }, []);
  // console.log(school_gateway_ratio?.getSchoolGatewayRatio);

  // const [allSchoolsIds, setAllSchoolsIds] = useState<any>([]);

  const [updateGatewayRatio, { loading }] = useMutation(ADD_GATEWAY_RATIO, {
    refetchQueries: [
      {
        query: GET_SCHOOL_GATEWAY_RATIO,
        variables: { school_id: schoolId },
      },
    ],
  });
  const [paymentMethods, setPaymentMethods] = useState<any>([]);

  useEffect(() => {
    const updatedPaymentMethods =
      school_gateway_ratio?.getSchoolGatewayRatio?.map((method: any) => {
        return {
          ...method,
          easebuzz: 100 - method.cashfree,
        };
      });
    setPaymentMethods(updatedPaymentMethods);
  }, [school_gateway_ratio]);

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedPaymentMethods = paymentMethods?.map(
      (method: any, i: number) => {
        if (i === index) {
          return {
            ...method,
            [field]: Number(value),
            easebuzz: 100 - Number(value),
          };
        }
        return method;
      }
    );

    setPaymentMethods(updatedPaymentMethods);
  };
  const gatwayRatio = paymentMethods?.map((d: any) => {
    return {
      cashfree: d?.cashfree,
      easebuzz: d?.easebuzz,
      mode: d.mode,
    };
  });

  return (
    <div>
      <div className="p-4 flex bg-white justify-between items-center overflow-hidden">
        <p
          onClick={() => {
            const val = confirm("Changes will not be saved");
            if (val) {
              setOpen(false);
            }
          }}
          className="flex text-xs cursor-pointer text-[#505E8E] items-center"
        >
          <MdArrowBackIos className="mr-2" />
          Back
        </p>
      </div>
      <div className="px-8 space-y-2 py-4 bg-white">
        {/* <ToolTip
          text={data?.getSchoolMdrInfo.school_name}
          width="max-w-[28rem]"
        >
          <h1 className=" text-xl w-full capitalize  truncate font-medium text-edviron_black">
            School Name: {data?.getSchoolMdrInfo.school_name}
          </h1>
        </ToolTip> */}
      </div>
      {gateway_ratio_loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <AiOutlineLoading3Quarters className="text-4xl mx-auto animate-spin" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 p-4">
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                Payment Mode
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Gateway 1</p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Gateway 2</p>
            </div>
          </div>

          <div>
            {paymentMethods?.map((method: any, index: number) => (
              <div key={index} className="grid gap-8 grid-cols-3 p-4">
                <h3 className=" text-[#1B163B] text-base uppercase font-normal">
                  {method.mode}
                </h3>

                <input
                  type="number"
                  value={method.cashfree}
                  onChange={(e) =>
                    handleInputChange(index, "cashfree", e.target.value)
                  }
                  className={
                    "focus:outline-none disabled:bg-gray-200 transition-all duration-150 ease-in-out text-xs font-normal text-[#1B163B] px-2 py-1.5 w-full lg:max-w-[80%] rounded-md text-right "
                  }
                  min={0}
                  max={100}
                />

                <input
                  type="number"
                  disabled
                  value={method.easebuzz}
                  className={
                    "focus:outline-none disabled:bg-gray-200 transition-all duration-150 ease-in-out text-xs font-normal text-[#1B163B] px-2 py-1.5 w-full lg:max-w-[80%] rounded-md text-right "
                  }
                />
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-end items-center pr-10 pt-6">
        <button
          onClick={async () => {
            try {
              const res = await updateGatewayRatio({
                variables: {
                  school_id: [schoolId],
                  trustee_id: trusteeId,
                  gateway_ratio: gatwayRatio,
                },
              });

              if (res.data?.addGatewayRatio) {
                if (res.data?.addGatewayRatio?.error) {
                  toast.error(res.data?.addGatewayRatio?.error);
                }
                if (res.data?.addGatewayRatio?.msg) {
                  toast.success(res.data?.addGatewayRatio?.msg);
                }
                setOpen(false);
              }
            } catch (error: any) {
              toast.error(error.message);
              setOpen(false);
            }
          }}
          className="px-10 py-1 w-40 h-10 bg-[#6F6AF8] disabled:bg-gray-300 disabled:text-black text-white rounded-md"
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="text-xl mx-auto animate-spin" />
          ) : (
            "Set Ratio"
          )}
        </button>
      </div>
    </div>
  );
}

export default SetSchoolGatwayRate;
