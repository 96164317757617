import React, { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Modal from "../../../../../components/Modal/Modal";
import Input from "../../../../../components/Input/Input";
import Select from "../../../../../components/Select/Select";
import { useMutation } from "@apollo/client";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import { GET_INVOICES, UPDATE_INVOICE } from "../Queries";
import { useLocation } from "react-router-dom";
function UpdateInvoice({ data }: any) {
  const searchId = useLocation();
  const id = searchId.pathname.slice(12);
  const [openModal, setOpenModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState({
    name: "APPROVED",
  });

  const [updateInvoice, { loading }] = useMutation(UPDATE_INVOICE, {
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: {
          limit: 200,
          page: 1,
          trustee_id: id,
        },
      },
    ],
  });
  return (
    <div>
      <Modal
        open={openModal}
        title="Update Invoice"
        setOpen={setOpenModal}
        className=" h-[16rem]"
      >
        <Input
          type="text"
          onChange={(v: any, e: any) => {
            setReason(e);
          }}
          name="Reason"
          add_error={() => {}}
        />

        <Select
          label="Status"
          options={["APPROVED", "REJECTED", "PENDING"].map((c: any) => {
            return { name: c };
          })}
          setSelected={setStatus}
          selected={status}
        />
        <div className="flex">
          <button
            onClick={async () => {
              try {
                const res = await updateInvoice({
                  variables: {
                    invoice_id: invoiceId,
                    status: status.name,
                    reason: reason,
                  },
                });
                if (res.data.updateInvoice) {
                  toast.success(res.data.updateInvoice);
                  setOpenModal(false);
                }
              } catch (error: any) {
                toast.error(error.message);
              }
            }}
            className="px-4 py-1 h-10  bg-[#6F6AF8] rounded-md text-white w-[150px] mx-auto mt-[10px]"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="text-xl mx-auto animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      <HiOutlineDotsVertical
        onClick={() => {
          setOpenModal(true);
          setInvoiceId(data?._id);
        }}
        className=" cursor-pointer mx-auto"
      />
    </div>
  );
}

export default UpdateInvoice;
