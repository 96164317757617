import React, { useEffect, useState } from "react";
import BackButton from "../../../../components/BackButton/BackButton";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ALL_TRUSTEE,
  GET_ALL_TRUSTEE_SCHOOL,
} from "../ManagePlatformCharges/Queries";
import RateChargeModal from "../../../../components/Modal/RateChargeModal";
import SetGatwayRate from "./SetGatwayRate";
function ManageGatways() {
  const [openSetGatwayModal, setOpenSetGatwayModal] = useState(false);

  return (
    <div>
      <RateChargeModal
        open={openSetGatwayModal}
        setOpen={setOpenSetGatwayModal}
      >
        <SetGatwayRate setOpen={setOpenSetGatwayModal} />
      </RateChargeModal>
      <div
        className="flex justify-between items-center
      "
      >
        <BackButton label="Manage Gatways" />

        <button
          className="px-4 py-1 bg-[#6F6AF8] disabled:bg-gray-300 disabled:text-black rounded-md text-white"
          onClick={() => setOpenSetGatwayModal(true)}
        >
          Set Gatways
        </button>
      </div>
    </div>
  );
}

export default ManageGatways;
