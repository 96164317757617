/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import ToolTip from "../../../../../../components/ToolTip";
import { MdArrowBackIos } from "react-icons/md";
import OverviewTab from "./Tabs/OverviewTab";

function RequestTab({ data, setOpen }: any) {
  const [activeTab, setActiveTab] = useState(1);
  const bulkSchoolNames = data?.schools
    ?.map((s: any) => s?.school_name)
    .filter((d: any) => d !== undefined);

  return (
    <div>
      <div className="p-4 flex bg-white justify-between items-center overflow-hidden">
        <p
          onClick={() => {
            setOpen(false);
          }}
          className="flex text-xs cursor-pointer text-[#505E8E] items-center"
        >
          <MdArrowBackIos className="mr-2" />
          Back
        </p>
        <p className=" text-[#1B163B] text-sm font-medium">
          Status: <span className=" text-[#6687FF] ">{data?.status}</span>
        </p>
      </div>
      <div className="px-8 space-y-2 py-4 bg-white">
        <ToolTip
          text={
            bulkSchoolNames === undefined || null
              ? data?.school?.school_name
              : bulkSchoolNames?.join(",")
          }
          width="max-w-[28rem]"
        >
          <h1 className=" text-xl w-full capitalize  truncate font-medium text-edviron_black">
            School Name:{" "}
            {bulkSchoolNames === undefined || null
              ? data?.school?.school_name
              : bulkSchoolNames?.join(",")}
          </h1>
        </ToolTip>

        <p className="max-w-[28rem] truncate text-sm font-medium text-edviron_black">
          School ID: {data?.school_id?.join(",")}
        </p>
        <p className="  text-sm font-normal text-edviron_black">
          Last Updated: {new Date(data.updatedAt).toLocaleDateString("hi")}
        </p>
      </div>
      <div className=" bg-white space-x-6">
        <button
          onClick={() => setActiveTab(1)}
          className={
            activeTab === 1
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px] "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Overview
        </button>
        <button
          onClick={() => setActiveTab(2)}
          className={
            activeTab === 2
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px]  "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Previous Rate
        </button>
        <button
          onClick={() => setActiveTab(3)}
          className={
            activeTab === 3
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px]  "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Final Rate
        </button>
      </div>
      {activeTab === 1 && (
        <OverviewTab
          status={data?.status}
          requestId={data?._id}
          data={data?.platform_charges}
          schoolId={data?.school_id}
          trusteeId={data?.trustee_id}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

export default RequestTab;
