import { gql } from "@apollo/client";

export const GET_ALL_TRUSTEE = gql`
  query getAllTrusteeInSinglePage {
    getAllTrusteeInSinglePage {
      _id
      name
    }
  }
`;

export const GET_ALL_TRUSTEE_SCHOOL = gql`
  query getAllTrusteeSchool($trusteeId: String!) {
    getAllTrusteeSchool(trusteeId: $trusteeId) {
      _id
      school_id
      school_name
      platform_charges {
        payment_mode
        platform_type
        range_charge {
          charge
          upto
          charge_type
        }
      }
    }
  }
`;

export const ADD_PLATFORM_CHARGE = gql`
  mutation AddPlatformCharge(
    $trusteeSchoolId: String!
    $platform_type: String!
    $payment_mode: String!
    $range_charge: [rangeChargeInput!]!
  ) {
    AddPlatformCharge(
      trusteeSchoolId: $trusteeSchoolId
      platform_type: $platform_type
      payment_mode: $payment_mode
      range_charge: $range_charge
    ) {
      payment_mode
      platform_type
      range_charge {
        charge
        upto
        charge_type
      }
    }
  }
`;

export const DELETE_PLATFORM_CHARGE = gql`
  mutation deletePlatformCharge(
    $trusteeSchoolId: String!
    $platform_type: String!
    $payment_mode: String!
  ) {
    deletePlatformCharge(
      trusteeSchoolId: $trusteeSchoolId
      platform_type: $platform_type
      payment_mode: $payment_mode
    ) {
      payment_mode
      platform_type
      range_charge {
        charge
        upto
        charge_type
      }
    }
  }
`;

export const UPDATE_PLATFORM_CHARGE = gql`
  mutation updatePlatformCharge(
    $trusteeSchoolId: String!
    $platform_type: String!
    $payment_mode: String!
    $range_charge: [rangeChargeInput!]!
  ) {
    updatePlatformCharge(
      trusteeSchoolId: $trusteeSchoolId
      platform_type: $platform_type
      payment_mode: $payment_mode
      range_charge: $range_charge
    ) {
      payment_mode
      platform_type
      range_charge {
        charge
        upto
        charge_type
      }
    }
  }
`;

export const BULK_MDR_UPLOAD = gql`
  mutation BulkMDRUpload(
    $trusteeSchoolId: String!
    $platform_charges: [platformChargeInput!]!
  ) {
    BulkMDRUpload(
      platform_charges: $platform_charges
      trusteeSchoolId: $trusteeSchoolId
    ) {
      error
    }
  }
`;

export const ADD_GATEWAY_RATIO = gql`
  mutation AddGatewayRatio(
    $gateway_ratio: [AddGatewayRatioInput!]!
    $school_id: [String!]!
    $trustee_id: String!
  ) {
    addGatewayRatio(
      gateway_ratio: $gateway_ratio
      school_id: $school_id
      trustee_id: $trustee_id
    ) {
      error
      msg
      not_found
      school_not_found
    }
  }
`;
