import React, { useContext, useEffect, useState } from "react";
import SchoolsUpdateViaCsv from "./SchoolsUpdateViaCsv";
import Table from "../../../../components/Table/Table";
import Modal from "../../../../components/Modal/Modal";
import {
  MutationTuple,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import { GET_TRUSTEES } from "../ManageTrustees/Querries";
import Input from "../../../../components/Input/Input";
import Form from "../../../../components/Form/Form";
import Select from "../../../../components/Select/Select";
import { GET_PG_SCHOOLS_QUERY, PG_SCHOOL_KYC_STATUS_UPDATE } from "./Querries";
import { toast } from "react-toastify";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const PgKycStatus = ({ schoolId, handleRefetch }: any) => {
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [fullKyc, setFullKyc] = useState({
    name: "FULL_KYC_APPROVED",
  });
  const [MinKyc, setMinKyc] = useState({
    name: "MIN_KYC_APPROVED",
  });

  const [pgSchoolKycStatusUpdate, { loading }] = useMutation(
    PG_SCHOOL_KYC_STATUS_UPDATE
  );

  return (
    <>
      <HiOutlineDotsVertical
        onClick={() => {
          setStatusUpdate(!statusUpdate);
        }}
        className="cursor-pointer mx-auto"
      />
      {statusUpdate && (
        <div
          onMouseLeave={() => {
            setStatusUpdate(false);
          }}
          className="absolute space-y-6 right-28 p-8 w-96 bg-white border z-20 rounded-md"
        >
          <p className="text-sm font-semibold">update kyc status</p>

          <Select
            options={[
              "MIN_KYC_APPROVED",
              "MIN_KYC_PENDING",
              "MIN_KYC_REJECTED",
            ].map((c: any) => {
              return { name: c };
            })}
            setSelected={setMinKyc}
            selected={MinKyc}
          />
          <Select
            options={[
              "FULL_KYC_APPROVED",
              "FULL_KYC_PENDING",
              "FULL_KYC_REJECTED",
            ].map((c: any) => {
              return { name: c };
            })}
            setSelected={setFullKyc}
            selected={fullKyc}
          />
          <button
            onClick={async () => {
              try {
                const res = await pgSchoolKycStatusUpdate({
                  variables: {
                    full_kyc_status: fullKyc?.name,
                    min_kyc_status: MinKyc?.name,
                    pg_school_id: schoolId,
                  },
                });
                if (res?.data?.update_pg_school_kyc_status) {
                  toast.success("School KYC Status Is Updated");
                  handleRefetch();
                }
              } catch (err) {
                console.log(err);
              }
            }}
            className=" px-4 w-48 py-2 rounded-md bg-[#6F6AF8] text-white"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className=" animate-spin mx-auto font-bold text-xl" />
            ) : (
              "Update status"
            )}
          </button>
        </div>
      )}
    </>
  );
};

function ManageSchools() {
  const { data, refetch } = useQuery(GET_PG_SCHOOLS_QUERY);
  const handleRefetch = () => {
    refetch();
  };

  const { data: trusteesData } = useQuery(GET_TRUSTEES, {
    variables: {
      page: 1,
      pageSize: 50,
    },
  });

  const [schoolId, setSchoolId] = useState<any>("");
  const [schoolName, setSchoolName] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [open, setOpen] = useState(false);
  const [trustee, setTrustee] = useState<any>({});
  const [pgSchools, setPgSchools] = useState({ pg_schools: [] });

  useEffect(() => {
    setTrustee({
      name: trusteesData?.getAllTrustee?.data[0]?.name,
      id: trusteesData?.getAllTrustee?.data[0]?._id,
    });
  }, [trusteesData]);

  useEffect(() => {
    setPgSchools(data);
  }, [data]);

  return (
    <div>
      <SchoolsUpdateViaCsv refetchPgSchools={handleRefetch} />

      <div className="grid grid-cols-7">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {pgSchools && (
        <Table
          data={[
            [
              "Merchant Name",
              "Merchant Email",
              "Merchant Status",
              "PG Min KYC",
              "PG Full KYC",
              "Trustee Name",
              "Action",
            ],
            ...pgSchools?.pg_schools?.map((school: any) => [
              <div>{school.merchantName}</div>,
              <div className=" truncate">{school.merchantEmail}</div>,
              <div>{school.merchantStatus}</div>,
              <div>{school.minKycStatus}</div>,
              <div>{school.fullKycStatus}</div>,
              <div>{school?.trusteeName}</div>,
              <div className=" relative">
                <PgKycStatus
                  handleRefetch={handleRefetch}
                  schoolId={school._id}
                />
              </div>,
            ]),
          ]}
        />
      )}
    </div>
  );
}

export default ManageSchools;
