import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Form from "../../../../components/Form/Form";
import Input from "../../../../components/Input/Input";
import { preventNegativeValues } from "../../../../helper/preventNegativeValues";
import { preventPasteNegative } from "../../../../helper/preventPasteNegative";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_TRUSTEE,
  GENERATE_TOKEN_FOR_LOGIN_TO_TRUSTEE,
  GET_TRUSTEES,
} from "./Querries";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function OnboardTrustee({ trustee, setTrustee }: any) {
  const [generateToken, { loading }] = useMutation(
    GENERATE_TOKEN_FOR_LOGIN_TO_TRUSTEE
  );
  return (
    <div className="grid grid-cols-5 text-center grid-body items-center p-3 my-2 odd:bg-[#ECEDFB] even:bg-white odd:border-none even:border border-[#ECEDFB] rounded-lg">
      <div>{trustee.name}</div>
      <div className="lowercase">{trustee.email_id}</div>
      <div>{trustee.school_limit}</div>
      <div>{trustee.phone_number}</div>
      <div>
        <button
          onClick={async () => {
            const res = await generateToken({
              variables: {
                trustee_id: trustee?._id,
              },
            });
            if (res?.data?.loginToTrusteeWithAdmin) {
              window.open(
                `${process.env.REACT_APP_TRUSTEE_DASHBOARD_URL}/admin?token=${res?.data?.loginToTrusteeWithAdmin?.token}`,
                "_blank"
              );
            }
          }}
          className="px-4 w-48 py-2 rounded-md bg-[#6F6AF8] text-white"
        >
          {loading ? (
            <AiOutlineLoading3Quarters className=" animate-spin mx-auto font-bold text-xl" />
          ) : (
            "Login to Dashboard"
          )}
        </button>
      </div>
    </div>
  );
}

function ManageTrustee() {
  const [showModal, setShowModal] = React.useState(false);
  const [trustee, setTrustee] = useState<any>(null);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Adjust as needed

  const [createTrustee, { data: result, loading: LOAD, error: _ERROR }] =
    useMutation(CREATE_TRUSTEE, {
      refetchQueries: [{ query: GET_TRUSTEES }],
    });

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [password_error, setPassword_error] = React.useState("");

  const {
    data: trusteesData,
    loading: trusteesLoading,
    error: trusteesError,
    refetch,
  } = useQuery(GET_TRUSTEES, {
    variables: {
      page: currentPage,
      pageSize: pageSize,
    },
  });
  const trustees = trusteesData?.getAllTrustee?.data || [];
  const totalPages = trusteesData?.getAllTrustee?.totalPages || 1;

  useEffect(() => {
    if (password !== confirmPassword) {
      setPassword_error("Password Does Not Match");
    } else {
      setPassword_error("");
    }
  }, [password, confirmPassword]);
  return (
    <div>
      <Modal
        className="max-w-lg w-full"
        open={showModal}
        setOpen={setShowModal}
        title="Create Trustee"
      >
        <Form
          onSubmit={async (data: any) => {
            const trusteeInput = {
              name: data["Name"],
              email: data["Email"],
              phone_number: data["Phone Number"],
              school_limit: parseFloat(data["School Limit"]),
              password,
            };

            const res = await createTrustee({
              variables: {
                input: trusteeInput, // Make sure to provide the "input" variable
              },
            });
            if (!res) {
              toast.error("Cant able to create trustee");
            } else {
              toast.success("New Trustee created successfully");
            }
            setShowModal(false);
          }}
        >
          <Input
            type="name"
            placeholder="Enter Name"
            name="Name"
            add_error={() => {}}
            required
          />
          <Input
            type="email"
            name="Email"
            placeholder="Enter Email"
            add_error={() => {}}
            required
          />
          <Input
            type="number"
            name="Phone Number"
            add_error={() => {}}
            placeholder="Enter Phone no"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            maxLength={10}
            required
          />
          <Input
            type="number"
            name="School Limit"
            add_error={() => {}}
            placeholder="Enter School Limit"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            maxLength={3}
            required
          />
          <Input
            type="password"
            name="Password"
            add_error={() => {}}
            onChange={(v: any) => {
              setPassword(v);
            }}
            placeholder="Enter Password"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            required
          />
          <Input
            type="password"
            name="Confirm Password"
            add_error={() => {}}
            onChange={(v: any) => {
              setConfirmPassword(v);
            }}
            placeholder="Enter Confirm Password"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            required
          />
          <div>
            {password_error && (
              <p className="text-center text-red-500 font-normal">
                {password_error}
              </p>
            )}
          </div>
          <div className="mt-2 mb-2 text-center">
            <button
              disabled={password !== confirmPassword}
              className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6F6AF8] text-white"
            >
              Create
            </button>
          </div>
        </Form>
      </Modal>
      <button
        onClick={() => setShowModal(!showModal)}
        className="px-4 py-2 mb-5 bg-[#6F6AF8] rounded-md text-white float-right"
      >
        create new Trustee
      </button>
      <div className="w-full mt-20">
        <div>
          <div className="mt-10 w-full">
            <div className="table w-full shadow p-8 rounded-lg my-2 text-[#1e1b59]">
              <div className="grid grid-cols-5 grid-header p-3 font-semibold  bg-[#ECEDFB] rounded-t-lg text-violet-900">
                <div className="text-center">name</div>
                <div className="text-center">email</div>
                <div className="text-center">school limit</div>
                <div className="text-center">phone number</div>
                <div className="text-center">Action</div>
              </div>
              {trustees?.map((trustee: any, i: number) => (
                <OnboardTrustee
                  trustee={trustee}
                  setTrustee={setTrustee}
                  setShowModal={setShowModal}
                  setShowUpdateModal={setShowUpdateModal}
                  showUpdateModal={showUpdateModal}
                  key={i}
                />
              ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
                onClick={async () => {
                  setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
                  await refetch({
                    page: currentPage > 1 ? currentPage - 1 : 1,
                    pageSize: pageSize,
                  });
                }}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="px-4 py-2 rounded-md text-gray-500 text-lg font-bold">
                {" "}
                {currentPage}/{totalPages}{" "}
              </span>
              <button
                className="ml-2 px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
                onClick={async () => {
                  setCurrentPage(currentPage + 1);
                  await refetch({
                    page: currentPage + 1,
                    pageSize: pageSize,
                  });
                }}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTrustee;
