import React, { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Modal from "../../../../../../components/Modal/Modal";
import Input from "../../../../../../components/Input/Input";
import Select from "../../../../../../components/Select/Select";
import { useMutation } from "@apollo/client";
import { UPDATE_REFUND_REQUESTS, INITIATE_REQUEST } from "../../Queries";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import ConfirmationBox from "../../../../../../components/ConfermationBox";
function RefundRequestTab({ data, refetch }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [refundId, setRefundId] = useState("");
  const [status, setStatus] = useState({
    name: "PROCESSED",
  });
  console.log(data);

  const [updateRefundStatus, { loading }] = useMutation(UPDATE_REFUND_REQUESTS);
  const [initiateRefund, { loading: initiateLoading }] =
    useMutation(INITIATE_REQUEST);
  return (
    <div>
      <Modal open={openModal} setOpen={setOpenModal} className=" max-w-xl">
        <ConfirmationBox
          setOpen={setOpenModal}
          funtion={async () => {
            const res = await initiateRefund({
              variables: {
                amount: data?.refund_amount,
                collect_id: data?.order_id,
                refund_id: data?._id,
              },
            });
            if (res.data.initiateRefund) {
              toast.success(res.data.initiateRefund);
              refetch();
            }
          }}
          confirmationText="Initiat Request"
          ButtonText="Confirm Request"
        />
        {/* <Input
          type="text"
          name="Order Amount"
          value={data.order_amount}
          add_error={() => {}}
          disabled
        />
        <Input
          type="text"
          name="Transaction Amount"
          value={data.transaction_amount}
          add_error={() => {}}
          disabled
        />
        <Input
          type="text"
          name="Refund Amount"
          value={data.refund_amount}
          add_error={() => {}}
          disabled
        />
        <Select
          label="Status"
          options={["APPROVED", "REJECTED"].map((c: any) => {
            return { name: c === "APPROVED" ? "PROCESSED" : c };
          })}
          setSelected={(d: any) => {
            setStatus(() => {
              if (d.name === "PROCESSED") {
                return { name: "APPROVED" };
              } else {
                return d;
              }
            });
          }}
          selected={{
            name: status.name === "APPROVED" ? "PROCESSED" : status.name,
          }}
        />
        <div className="flex">
          <button
            onClick={async () => {
              try {
                const res = await updateRefundStatus({
                  variables: {
                    refund: refundId,
                    status: status.name,
                  },
                });
                if (res.data.updateRefundRequest) {
                  toast.success(res.data.updateRefundRequest);
                  setOpenModal(false);
                  refetch();
                }
              } catch (error: any) {
                toast.error(error.message);
              }
            }}
            className="px-4 py-1 h-10  bg-[#6F6AF8] rounded-md text-white w-[150px] mx-auto mt-[10px]"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="text-xl mx-auto animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </div> */}
      </Modal>
      {data.status === "INITIATED" ? (
        <HiOutlineDotsVertical
          onClick={() => {
            setOpenModal(true);
            setRefundId(data?._id);
          }}
          className=" cursor-pointer mx-auto"
        />
      ) : (
        "-"
      )}
    </div>
  );
}

export default RefundRequestTab;
