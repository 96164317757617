import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../../../../components/Modal/Modal";

const EditOptions = ({
  deletePlatformCharge,
  trusteeSchoolId,
  platform_type,
  payment_mode,
  setSelectTrusteeSchool,
  selectTrusteeSchool,
  setTrusteeSchools,
  truseteeSchools,
  setViewCharges,
  setMdr,
  range_charges,
}: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="z-10 absolute bottom-0 rounded-lg shadow bg-[#F8FAFB]">
      <Modal open={open} setOpen={setOpen} title="This action cannot be revert">
        <div className="mt-10 mb-10 grid grid-cols-2 gap-4">
          <button
            className="py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className={`py-2 text-white px-10 rounded-lg hover:bg-red-400 bg-red-300`}
            onClick={async () => {
              const { data } = await deletePlatformCharge({
                variables: {
                  trusteeSchoolId,
                  platform_type,
                  payment_mode,
                },
              });

              truseteeSchools.map((truseteeSchool: any) => {
                if (truseteeSchool.value._id === trusteeSchoolId) {
                  truseteeSchool.value.platform_charges =
                    data.deletePlatformCharge;
                }
              });

              selectTrusteeSchool.value.platform_charges =
                data.deletePlatformCharge;

              setSelectTrusteeSchool(selectTrusteeSchool);
              setTrusteeSchools(truseteeSchools);

              toast.success("Successfully deleted");

              setOpen(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>
      <div
        onClick={() => {
          setViewCharges(true);
          setMdr({
            platform_type,
            payment_mode,
            range_charges,
          });
        }}
        className="py-2 hover:bg-slate-200 rounded-t-lg cursor-pointer px-[25px]"
      >
        View
      </div>
      <hr />
      <div
        onClick={async () => {
          setOpen(true);
        }}
        className="py-2 text-red-400 hover:bg-slate-200  rounded-b-lg cursor-pointer px-[25px]"
      >
        Delete
      </div>
    </div>
  );
};

export default EditOptions;
