import { gql } from "@apollo/client";

export const UPDATE_PG_SCHOOL_MUTATION = gql`
  mutation CreateUpdatePgSchool($body: [PgSchoolType!]!) {
    create_update_pg_school(body: $body)
  }
`;

export const GET_PG_SCHOOLS_QUERY = gql`
  query Pg_schools {
    pg_schools {
      merchantName
      merchantEmail
      merchantStatus
      minKycStatus
      _id
      merchantId
      schoolId
      trusteeId
      trusteeName
    }
  }
`;
export const ASSIGN_TRUSTEE_TO_PG_SCHOOL = gql`
  mutation AssignSchool(
    $school_id: String!
    $school_name: String!
    $trustee_id: String!
    $client_id: String!
    $client_secret: String!
  ) {
    assignSchool(
      school_id: $school_id
      school_name: $school_name
      trustee_id: $trustee_id
      client_id: $client_id
      client_secret: $client_secret
    ) {
      school_id
      school_name
      trusteeId
      trusteeName
    }
  }
`;

export const PG_SCHOOL_KYC_STATUS_UPDATE = gql`
  mutation Update_pg_school_kyc_status(
    $full_kyc_status: FullKycStatus!
    $min_kyc_status: MinKycStatus!
    $pg_school_id: String!
  ) {
    update_pg_school_kyc_status(
      full_kyc_status: $full_kyc_status
      min_kyc_status: $min_kyc_status
      pg_school_id: $pg_school_id
    )
  }
`;
