import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GET_TRUSTEES } from "../ManageTrustees/Querries";
import BackButton from "../../../../components/BackButton/BackButton";
import Table from "../../../../components/Table/Table";
import { dashboardContext } from "../../Dashboard";
import Select from "../../../../components/Select/Select";
import { ASSIGN_ONBORDER_TO_TRUSTEE } from "./Queries";
import { toast } from "react-toastify";


const AssignOnboarder = ({ setShowOnboarder, erp_id, current_onboarder }: any) => {
  const { onboarders } = useContext(dashboardContext);
  const [assignOnboarderToTrustee] = useMutation(ASSIGN_ONBORDER_TO_TRUSTEE);

  const [onborder, set_onborder] = useState({
    name: "Select",
    id: null,
  });

  useEffect(() => {
    onboarders.onboarders.forEach((onboarder: any) => {
      console.log(onboarder);
      if (current_onboarder === onboarder._id) {
        set_onborder({
          name: onboarder.name,
          id: onboarder._id
        })
      }
    })
  }, [onboarders])


  return (
    <div
      className={`z-50 absolute  top-0 px-2 right-72 w-80 h-fit bg-[#F8FAFB] rounded-lg  shadow`}
      onMouseLeave={() => setShowOnboarder(false)}
    >
      <div className="px-4 pt-4 mb-2 flex items-center justify-between">
        <div>Assign Onboarder </div>
      </div>
      <hr />
      <Select
        setSelected={set_onborder}
        selected={onborder}
        options={onboarders?.onboarders.map((v: any) => {
          return { name: v.name, id: v?._id };
        })}
      />
      <div className="mt-6 mb-3 mr-4 text-right">
        <button
          onClick={async () => {
            if (onborder.id === null) {
              toast.error("please selct onboarder");
              return;
            }
            const res = await assignOnboarderToTrustee({
              variables: {
                erp_id: erp_id,
                onboarder_id: onborder.id,
              },
            });

            if (res) {
              toast.success("Onboarder successfully assigned");
              window.location.reload();
            }
            else toast.error("Onboarder not assigned please try again");
            setShowOnboarder(false);
          }}
          className="w-[108px] py-1 px-2 rounded-lg bg-[#6F6AF8] text-white"
        >
          {onborder.id ? "Update" : "Assign"}
        </button>
      </div>
    </div>
  );
};

const Onboarder = ({ data }: any) => {
  const [showOnboarder, setShowOnboarder] = useState(false);

  return (
    <div
      className="relative text-center cursor-pointer"
      onClick={() => setShowOnboarder(true)}
    >
      <i className="fa-solid fa-ellipsis-vertical"></i>
      {showOnboarder ? (
        <AssignOnboarder
          erp_id={data?._id}
          current_onboarder={data?.onboarder_id}
          setShowOnboarder={setShowOnboarder}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

const ManageTrusteeOnboarder = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 5;

  const {
    data: trusteesData,
    loading: trusteesLoading,
    error: trusteesError,
    refetch,
  } = useQuery(GET_TRUSTEES, {
    variables: {
      page: currentPage,
      pageSize: pageSize,
    },
  });

  useEffect(() => {
    console.log(trusteesError)
  }, [trusteesError])

  useEffect(() => {
    if (trusteesData) {
      setTotalPages(trusteesData.getAllTrustee.totalPages);
    }
  }, [trusteesData]);

  return (
    <>
      <BackButton label="Manage Trustee Onboarder" />

      {trusteesData ? (
        <Table
          data={[
            ["Name", "Phone Number", "Email ID", "School Limits", "Action"],
            ...trusteesData?.getAllTrustee.data.map((d: any, i: number) => [
              <div
                className="text-center text-violet-500 cursor-pointer"
              >
                {d.name}
              </div>,
              <div className="text-center">
                {d.phone_number}
              </div>,
              <div className="text-center">
                {d.email_id}
              </div>,
              <div>{d.school_limit}</div>,
              <Onboarder data={d} />
            ]),
          ]}
        />
      ) : (
        <div></div>
      )}


      {/* Pagination Controls */}
      <div className="flex justify-end mt-4">
        <button
          className="px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
          onClick={async () => {
            setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
            await refetch({
              page: currentPage > 1 ? currentPage - 1 : 1,
              pageSize: pageSize,
            });
          }}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="px-4 py-2 rounded-md text-gray-500 text-lg font-bold">
          {" "}
          {currentPage}/{totalPages}{" "}
        </span>
        <button
          className="ml-2 px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
          onClick={async () => {
            setCurrentPage(currentPage + 1);
            await refetch({
              page: currentPage + 1,
              pageSize: pageSize,
            });
          }}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default ManageTrusteeOnboarder;