import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useMutation } from "@apollo/client";
import { BULK_MDR_UPLOAD } from "../Queries";
import { toast } from "react-toastify";
import KmRateCard from "./Range";
import Modal from "../../../../../components/Modal/Modal";

const ViewOnlyRangeCharge = ({ mdr }: any) => {
  let breakPoint: any = [];
  let fees: any = [];
  let card: any = [];
  let type: any = [];

  mdr.range_charge.map((charge: any, index: number) => {
    if (charge.upto) breakPoint.push(charge.upto);
    fees.push(charge.charge);
    card.push(index + 1);
    type.push(charge.charge_type);
  });

  return (
    <KmRateCard
      breakPoint_data={breakPoint}
      fees_data={fees}
      card_data={card}
      amountType_data={type}
    />
  );
};

const BulkMDR = ({ trusteeSchoolId }: any) => {
  const [platformCharges, setPlatformCharges] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const allowedExtensions = ["csv"];

  const [BulkMDRUpload, { loading, error: BulkMDRUpload_error }] =
    useMutation(BULK_MDR_UPLOAD);

  const handleFileChange = (e: any) => {
    setError(null);
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      handleParse(inputFile);
    }
  };
  const handleParse = (file: any) => {
    if (!file) return alert("Enter a valid file");

    const reader = new FileReader();

    reader.onload = async ({ target }: any) => {
      const csv: any = Papa.parse(target.result, {
        header: true,
      });

      let isAnyError = 0;
      csv.data.forEach((element: any) => {
        const fields = Object.keys(element);
        if (
          fields.length !== 3 ||
          fields[0] !== "platform_type" ||
          fields[1] !== "payment_mode" ||
          fields[2] !== "range_charge"
        ) {
          setError("Please enter csv file of correct format");
          isAnyError = 1;
          return;
        }
        try {
          element.range_charge = JSON.parse(element.range_charge);
        } catch (err: any) {
          isAnyError = 1;
          setError(err.message);
          return;
        }
      });

      if (isAnyError) {
        return;
      }
      setPlatformCharges(csv.data);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setPlatformCharges(null);
    }
  }, [error]);

  useEffect(() => {
    if (BulkMDRUpload_error) {
      toast.error(BulkMDRUpload_error.message);
    }
  }, [BulkMDRUpload_error]);

  const [viewCharge, setViewCharge] = useState(false);
  const [mdrValue, setMdrValue] = useState<any>(null);

  return (
    <div className="content-center flex flex-col">
      <Modal
        open={viewCharge}
        setOpen={setViewCharge}
        title="Charges"
        className="max-w-3xl"
      >
        <ViewOnlyRangeCharge mdr={mdrValue} />
      </Modal>
      <input
        onChange={handleFileChange}
        id="csvInput"
        name="file"
        type="File"
        className="p-[5px] border-[#6F6AF8] border-[2px] mx-auto text-xs rounded-md my-[15px]"
      />

      {platformCharges && (
        <div className="w-full content-center flex flex-col">
          <div className="w-full">
            <div>
              <div className="w-full">
                <div className="table w-full shadow p-8 rounded-lg my-2 text-[#1e1b59]">
                  <div className="grid grid-cols-3 grid-header p-3 font-semibold  bg-[#ECEDFB] rounded-t-lg text-violet-900">
                    <div className="text-center">Platfrom Type</div>
                    <div className="text-center">Payment Mode</div>
                    <div className="text-center">Action</div>
                  </div>
                  {platformCharges?.map((mdr: any, index: number) => {
                    return (
                      <div
                        className={`grid grid-cols-3 text-center grid-body items-center p-3 my-2 ${
                          response && response[index].error
                            ? "bg-[#ffbbbb]"
                            : "bg-[#a4e9a6]"
                        } ${
                          !response ? "odd:bg-[#ECEDFB] even:bg-white" : ""
                        } odd:border-none even:border border-[#ECEDFB] rounded-lg`}
                      >
                        <div>{mdr.platform_type}</div>
                        <div>{mdr.payment_mode}</div>
                        <div>
                          <button
                            onClick={() => {
                              setViewCharge(true);
                              setMdrValue(mdr);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <button
            className="px-4 py-1 bg-[#6F6AF8] rounded-md text-white w-[150px] mx-auto mt-[10px]"
            onClick={async () => {
              setResponse(null);
              const { data } = await BulkMDRUpload({
                variables: {
                  trusteeSchoolId: trusteeSchoolId,
                  platform_charges: platformCharges,
                },
              });

              setResponse(data.BulkMDRUpload);

              let success = 0;
              let failure = 0;
              data.BulkMDRUpload.forEach((element: any) => {
                if (element.error) failure++;
                else success++;
              });

              toast.success(`${success} success ${failure} failure`);
            }}
          >
            Upload
          </button>
        </div>
      )}
    </div>
  );
};

export default BulkMDR;
