// Querries.ts for Trustees

import { gql } from "@apollo/client";

// GraphQL query for getting all trustees
export const GET_TRUSTEES = gql`
  query getAllTrustee($page: Int = 1, $pageSize: Int = 10) {
    getAllTrustee(page: $page, pageSize: $pageSize) {
      data {
        _id
        name
        email_id
        school_limit
        phone_number
        onboarder_id
      }
      page
      pageSize
      totalPages
      totalItems
    }
  }
`;

// GraphQL mutation for creating a trustee
export const CREATE_TRUSTEE = gql`
  mutation CreateTrustee($input: TrusteeInput!) {
    createTrustee(input: $input) {
      _id
      name
      email_id
      password_hash
      school_limit
      IndexOfApiKey
    }
  }
`;

export const GENERATE_TOKEN_FOR_LOGIN_TO_TRUSTEE = gql`
  mutation LoginToTrusteeWithAdmin($trustee_id: String!) {
    loginToTrusteeWithAdmin(trustee_id: $trustee_id) {
      token
    }
  }
`;
