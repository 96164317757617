const Banks = [
  { name: "AU Small Finance Bank", code: 3087, abbreviation: "AUBLR" },
  { name: "Airtel Payments Bank", code: 3123, abbreviation: "AIRPR" },
  { name: "Andhra Pragathi Grameena Bank", code: 3094, abbreviation: "APGBR" },
  { name: "Axis Bank", code: 3003, abbreviation: "UTIBR" },
  { name: "Axis Bank Corporate", code: 3071, abbreviation: "UTIBC" },
  { name: "Bandhan Bank - Retail Banking", code: 3088, abbreviation: "BDBLR" },
  {
    name: "Bandhan Bank- Corporate banking",
    code: 3079,
    abbreviation: "BDBLC",
  },
  { name: "Bank of Bahrain and Kuwait", code: 3095, abbreviation: "BBKMR" },
  { name: "Bank of Baroda - Corporate", code: 3060, abbreviation: "BARBC" },
  {
    name: "Bank of Baroda - Retail Banking",
    code: 3005,
    abbreviation: "BARBR",
  },
  { name: "Bank of India", code: 3006, abbreviation: "BKIDR" },
  { name: "Bank of India - Corporate", code: 3061, abbreviation: "BKIDC" },
  { name: "Bank of Maharashtra", code: 3007, abbreviation: "MAHBR" },
  {
    name: "Barclays Corporate- Corporate Banking",
    code: 3080,
    abbreviation: "BARCC",
  },
  { name: "Bassien Catholic Coop Bank", code: 3096, abbreviation: "BACBC" },
  { name: "CSB Bank Limited", code: 3010, abbreviation: "CSBKR" },
  { name: "Canara Bank", code: 3009, abbreviation: "CNRBR" },
  { name: "Capital Small Finance Bank", code: 3098, abbreviation: "CLBLR" },
  { name: "Central Bank of India", code: 3011, abbreviation: "CBINR" },
  { name: "City Union Bank", code: 3012, abbreviation: "CIUBR" },
  { name: "City Union Bank of Corporate", code: 3083, abbreviation: "CIUBC" },
  { name: "Cosmos Bank", code: 3097, abbreviation: "COSBR" },
  { name: "DBS Bank Ltd", code: 3017, abbreviation: "DBSSR" },
  { name: "DCB Bank - Corporate", code: 3062, abbreviation: "DCBLC" },
  { name: "DCB Bank - Personal", code: 3018, abbreviation: "DCBLR" },
  { name: "Deutsche Bank", code: 3016, abbreviation: "DEUTR" },
  { name: "Dhanlakshmi Bank", code: 3019, abbreviation: "DLXBR" },
  { name: "Dhanlaxmi Bank Corporate", code: 3072, abbreviation: "DLXBC" },
  { name: "ESAF Small Finance Bank", code: 3100, abbreviation: "ESMFR" },
  { name: "Equitas Small Finance Bank", code: 3076, abbreviation: "ESFBR" },
  { name: "Federal Bank", code: 3020, abbreviation: "FDRLR" },
  { name: "Fincare Bank", code: 3101, abbreviation: "FSFBR" },
  {
    name: "Gujarat State Co-operative Bank Limited",
    code: 3091,
    abbreviation: "GSCBR",
  },
  { name: "HDFC Bank", code: 3021, abbreviation: "HDFCR" },
  { name: "HDFC Corporate", code: 3084, abbreviation: "HDFCC" },
  { name: "HSBC Retail NetBanking", code: 3092, abbreviation: "HSBCR" },
  { name: "ICICI Bank", code: 3022, abbreviation: "ICICR" },
  { name: "ICICI Corporate Netbanking", code: 3073, abbreviation: "ICICC" },
  { name: "IDBI Bank", code: 3023, abbreviation: "IBKLR" },
  { name: "IDFC FIRST Bank", code: 3024, abbreviation: "IDFBR" },
  { name: "Indian Bank", code: 3026, abbreviation: "IDIBR" },
  { name: "Indian Overseas Bank", code: 3027, abbreviation: "IOBAR" },
  { name: "Indian Overseas Bank Corporate", code: 3081, abbreviation: "IOBAC" },
  { name: "IndusInd Bank", code: 3028, abbreviation: "INDBR" },
  { name: "Jammu and Kashmir Bank", code: 3029, abbreviation: "JAKAR" },
  { name: "Jana Small Finance Bank", code: 3102, abbreviation: "JSFBR" },
  { name: "Janata Sahakari Bank Ltd Pune", code: 3104, abbreviation: "JSBPR" },
  { name: "Jio Payments Bank", code: 3103, abbreviation: "JIOPR" },
  { name: "Kalyan Janata Sahakari Bank", code: 3105, abbreviation: "KJSBR" },
  { name: "Karnataka Bank Ltd", code: 3030, abbreviation: "KARBR" },
  { name: "Karnataka Gramin Bank", code: 3113, abbreviation: "PKGBR" },
  { name: "Karnataka Vikas Grameena Bank", code: 3107, abbreviation: "KVGBR" },
  { name: "Karur Vysya Bank", code: 3031, abbreviation: "KVBLR" },
  { name: "Kotak Mahindra Bank", code: 3032, abbreviation: "KKBKR" },
  { name: "Lakshmi Vilas Bank - Corporate", code: 3064, abbreviation: "LAVBC" },
  {
    name: "Laxmi Vilas Bank - Retail Net Banking",
    code: 3033,
    abbreviation: "LAVBR",
  },
  { name: "Maharashtra Gramin Bank", code: 3108, abbreviation: "MAHGR" },
  { name: "Mehsana urban Co-op Bank", code: 3109, abbreviation: "MSNUR" },
  { name: "NKGSB Co-op Bank", code: 3111, abbreviation: "NKGSR" },
  {
    name: "North East Small Finance Bank Ltd",
    code: 3110,
    abbreviation: "NESFR",
  },
  {
    name: "Nutan Nagarik Sahakari Bank Limited",
    code: 3112,
    abbreviation: "NNSBR",
  },
  { name: "Punjab & Sind Bank", code: 3037, abbreviation: "PSIBR" },
  {
    name: "Punjab National Bank - Corporate",
    code: 3065,
    abbreviation: "PUNBC",
  },
  {
    name: "Punjab National Bank - Retail Banking",
    code: 3038,
    abbreviation: "PUNBR",
  },
  { name: "RBL Bank", code: 3039, abbreviation: "RATNR" },
  {
    name: "RBL Bank Limited - Corporate Banking",
    code: 3114,
    abbreviation: "RATNC",
  },
  { name: "SBM Bank India", code: 3115, abbreviation: "STCBR" },
  { name: "Saraswat Bank", code: 3040, abbreviation: "SRCBR" },
  { name: "Shamrao Vithal Bank Corporate", code: 3075, abbreviation: "SVCBC" },
  {
    name: "Shamrao Vitthal Co-operative Bank",
    code: 3041,
    abbreviation: "SVCBR",
  },
  { name: "Shivalik Bank", code: 3086, abbreviation: "SMCBR" },
  { name: "South Indian Bank", code: 3042, abbreviation: "SIBLR" },
  { name: "Standard Chartered Bank", code: 3043, abbreviation: "SCBLR" },
  { name: "State Bank Of India", code: 3044, abbreviation: "SBINR" },
  {
    name: "State Bank of India - Corporate",
    code: 3066,
    abbreviation: "SBINC",
  },
  { name: "Suryoday Small Finance Bank", code: 3116, abbreviation: "SURYR" },
  { name: "TJSB Bank", code: 3119, abbreviation: "TJSBR" },
  {
    name: "Tamil Nadu State Co-operative Bank",
    code: 3051,
    abbreviation: "TNSCR",
  },
  { name: "Tamilnad Mercantile Bank Ltd", code: 3052, abbreviation: "TMBLR" },
  { name: "Thane Bharat Sahakari Bank Ltd", code: 3118, abbreviation: "TBSBR" },
  {
    name: "The Kalupur Commercial Co-Operative Bank",
    code: 3106,
    abbreviation: "KCCBR",
  },
  {
    name: "The Surat Peoples Co-operative Bank Limited",
    code: 3090,
    abbreviation: "SPCBR",
  },
  { name: "The Sutex Co-op Bank Ltd", code: 3117, abbreviation: "SUTBR" },
  { name: "UCO Bank", code: 3054, abbreviation: "UCBAR" },
  { name: "UCO Bank Corporate", code: 3122, abbreviation: "UCBAC" },
  { name: "Union Bank of India", code: 3055, abbreviation: "UBINR" },
  {
    name: "Union Bank of India - Corporate",
    code: 3067,
    abbreviation: "UBINC",
  },
  { name: "Utkarsh Small Finance Bank", code: 3089, abbreviation: "UTKSR" },
  {
    name: "Varachha Co-operative Bank Limited",
    code: 3120,
    abbreviation: "VARAR",
  },
  { name: "Yes Bank Corporate", code: 3077, abbreviation: "YESBC" },
  { name: "Yes Bank Ltd", code: 3058, abbreviation: "YESBR" },
  {
    name: "Zoroastrian Co-Operative Bank Ltd",
    code: 3121,
    abbreviation: "ZCBLR",
  },
];

let netbankingBanks: any = [];

Banks.map((bank) => {
  netbankingBanks.push({ label: bank.name, value: bank.name });
});

export { netbankingBanks };
