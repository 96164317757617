import React from "react";
import Select from "react-select";
import { platform_type } from "../ManagePlatformCharges";
import KmRateCard from "./Range";

function SetPlatformChargesComponent({
  setPayementModes,
  paymentModes,
  selectedPaymentMode,
  setSelectedPaymentMode,
  setPlatformCharges,
}: any) {
  return (
    <div>
      <Select
        value={paymentModes}
        options={platform_type}
        onChange={(selectedOption) => {
          setPayementModes(selectedOption);
          setSelectedPaymentMode(null);
        }}
        placeholder="Select Platform Type"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      <Select
        options={paymentModes?.value}
        value={selectedPaymentMode}
        onChange={(selectedOption) => {
          setSelectedPaymentMode(selectedOption);
        }}
        placeholder="Select Payment Method"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      <KmRateCard updateMDR={setPlatformCharges} />
    </div>
  );
}

export default SetPlatformChargesComponent;
