/* eslint-disable no-eval */
import { useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
// import { CREATE_MDR_REQUEST } from "../../../../../../Qurries";
import { toast } from "react-toastify";
// import RateChargeModal from "../../../../../../components/Modal/RateChargeModal";
// import RequestTab from "../../RequestTab/RequestTab";
// import SingleSchoolRangeModal from "../SingleSchoolRangeModal";

const _ = require("lodash");
export const addFromValues = (arr: any) => {
  let previousUpto = 0;
  return arr.map((item: any) => {
    const newItem = { ...item, from: previousUpto };
    previousUpto = item.upto !== null ? item.upto : previousUpto;
    return newItem;
  });
};
const SchoolPaymentMode = ({
  mode,
  edit,
  handleFinalRatesChange,
  handleCommissionChange,
  indexOfPaymetMode,
  paymentModes,
  setPaymentModes,
}: any) => {
  const [rangeCharge, setRangeCharge] = useState<any>(mode?.range_charge);
  const handleCommisionChange = (commission: number, index: number) => {
    const newRangeCharge = [...rangeCharge];
    const updatedPaymentsMode = [...paymentModes];
    newRangeCharge[index] = {
      ...newRangeCharge[index],
      commission: commission,
      charge: commission + newRangeCharge[index]?.base_charge,
    };
    setRangeCharge(newRangeCharge);
    updatedPaymentsMode[indexOfPaymetMode] = {
      ...updatedPaymentsMode[indexOfPaymetMode],
      range_charge: newRangeCharge,
    };
    setPaymentModes(updatedPaymentsMode);
  };
  const handleFinalRateChange2 = (finalRate: number, index: number) => {
    const newRangeCharge = [...rangeCharge];
    newRangeCharge[index] = {
      ...newRangeCharge[index],
      commission: finalRate - newRangeCharge[index].base_charge,
      charge: finalRate,
    };
    setRangeCharge(newRangeCharge);
    const updatedPaymentsMode = [...paymentModes];
    updatedPaymentsMode[indexOfPaymetMode] = {
      ...updatedPaymentsMode[indexOfPaymetMode],
      range_charge: newRangeCharge,
    };
    setPaymentModes(updatedPaymentsMode);
  };
  const preventNegativeValues = (e: any) =>
    ["+", "-"].includes(e.key) && e.preventDefault();

  const filterdArray = addFromValues(
    _.sortBy(rangeCharge, (item: any) =>
      item.upto === null ? Infinity : item.upto
    )
  );

  return filterdArray.map((range: any, rangeIndex: number) => {
    return (
      <div className="grid gap-x-2 grid-cols-5" key={rangeIndex}>
        <div>
          <p className=" text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} (
            <span className="text-[8px]">{mode?.payment_mode}</span>)
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.upto
              ? `${range?.from > 0 ? range?.from : "<="} ${
                  range?.upto >= range?.from && range?.from !== 0
                    ? "-" + range?.upto
                    : range?.upto
                }`
              : `>=${range?.from}`}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.base_charge}
          </p>
        </div>
        <div>
          <input
            onKeyDown={preventNegativeValues}
            disabled={!edit}
            onChange={(e) => {
              handleCommisionChange(Number(e.target.value), rangeIndex);
            }}
            className={
              "focus:outline-none transition-all duration-150 ease-in-out text-xs font-normal text-[#1B163B] px-2 py-1.5 w-full lg:max-w-[80%] rounded-md text-right " +
              (edit === true ? " bg-white" : " bg-transparent")
            }
            type="number"
            value={range?.commission}
          />
        </div>
        <div>
          <input
            // onPaste={(e)=>{preventPasteNegative(e,range?.charge)}}
            disabled={!edit}
            min={range?.base_charge}
            onChange={(e) => {
              handleFinalRateChange2(Number(e.target.value), rangeIndex);
            }}
            className={
              "focus:outline-none transition-all duration-150 ease-in-out text-xs font-normal text-[#1B163B] px-2 py-1.5 w-full lg:max-w-[80%] rounded-md text-right" +
              (edit === true ? " bg-white" : " bg-transparent")
            }
            type="number"
            value={range?.charge}
          />
        </div>
      </div>
    );
  });
};
function RateTab({ data, setOpen, PENDING_REQUEST, setPaymentRange }: any) {
  const [paymentModes, setPaymentModes] = useState(data?.platform_charges);

  const [edit, setEdit] = useState(false);
  const [addDescription, setAddDescription] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);

  const handleFinalRatesChange = (
    platformIndex: number,
    rangeIndex: number,
    value: any
  ) => {
    const updatedPlatformCharges = cloneDeep(paymentModes);
    const obj = {
      ...updatedPlatformCharges[platformIndex].range_charge[rangeIndex],
      school_mdr: value,
    };
    updatedPlatformCharges[platformIndex].range_charge[rangeIndex] = obj;
    setPaymentModes(updatedPlatformCharges);
  };

  const mdr_request_arr = paymentModes?.map((mode: any) => {
    return {
      platform_type: mode?.platform_type,
      payment_mode: mode?.payment_mode,
      range_charge: mode.range_charge.map((range: any) => {
        return {
          charge: range?.charge,
          charge_type: range?.charge_type,
          upto: range?.upto,
        };
      }),
    };
  });
  //   console.log(data.platform_charges);

  return (
    <>
      {/* <RateChargeModal open={openRequestModal} setOpen={setOpenRequestModal}>
        <RequestTab data={PENDING_REQUEST[0]} setOpen={setOpenRequestModal} />
      </RateChargeModal> */}
      <div className="px-6 mt-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
        {!data?.platform_charges?.length ? (
          <p className=" text-xs text-[#1B163B]">
            The Institute is not live yet. You can view the details once the
            institute is live.
          </p>
        ) : (
          <>
            <div className={"flex items-center justify-between"}>
              {!edit ? (
                <div
                  className={
                    "flex w-full" +
                    (!PENDING_REQUEST.length
                      ? " justify-end"
                      : " justify-between")
                  }
                >
                  {!PENDING_REQUEST.length ? (
                    <p
                      onClick={() => {
                        setEdit(!edit);
                        setAddDescription(false);
                      }}
                      className="flex cursor-pointer items-center text-[#6687FF] text-xs font-normal"
                    >
                      <FiEdit className="mr-2" />
                      Edit
                    </p>
                  ) : (
                    <>
                      <p className="text-edviron_black text-xs font-medium">
                        We have received your request.{" "}
                        <span
                          onClick={() => {
                            setOpenRequestModal(true);
                          }}
                          className="text-[10px] cursor-pointer text-[#6687FF] font-normal"
                        >
                          View Here
                        </span>
                      </p>
                      <p
                        onClick={() => {
                          setEdit(!edit);
                        }}
                        className="flex cursor-pointer items-center text-[#6687FF] text-xs font-normal"
                      >
                        <FiEdit className="mr-2" />
                        Edit
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start gap-y-2">
                    <button
                      onClick={() => setAddDescription(!addDescription)}
                      className={
                        " text-sm" +
                        (addDescription === true
                          ? " text-[#1B163B]"
                          : " text-[#6687FF]")
                      }
                    >
                      + Add Description/Reason
                    </button>
                    <button
                      onClick={() => setAddDescription(!addDescription)}
                      className={
                        " text-sm" +
                        (addDescription === true
                          ? " text-[#1B163B]"
                          : " text-[#6687FF]")
                      }
                    >
                      + Add Range
                    </button>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <button
                      onClick={() => {
                        setEdit(false);
                        setAddDescription(false);
                      }}
                      className={
                        "  py-2 px-4 font-semibold bg-transparent border border-[#505E8E80] rounded-md text-[12px] text-left  text-[#717171BF]"
                      }
                    >
                      Cancel
                    </button>
                    <button
                    //   onClick={async () => {
                    //     try {
                    //       const res = await createMdr({
                    //         variables: {
                    //           school_id: [data?.school_id],
                    //           platform_charge: mdr_request_arr,
                    //         },
                    //       });

                    //       console.log({
                    //         school_id: [data?.school_id],
                    //         platform_charge: mdr_request_arr,
                    //       });

                    //       if (res?.data?.createMdrRequest) {
                    //         toast.success(res?.data?.createMdrRequest);
                    //         setOpen(false);
                    //       }
                    //     } catch (err) {
                    //       setOpen(false);
                    //     }
                    //   }}
                    //   className={
                    //     "bg-[#6687FF] px-4 py-2 font-semibold rounded-md text-[12px]  text-white"
                    //   }
                    >
                      Send Request
                    </button>
                  </div>
                </>
              )}
            </div>
            {addDescription && (
              <textarea
                className="bg-white h-28 rounded-md placeholder:text-[#777777] placeholder:text-xs focus:outline-none placeholder:font-normal p-4 w-full md:max-w-[60%]"
                placeholder="enter description here"
              ></textarea>
            )}

            {/* <SingleSchoolRangeModal /> */}
            <div className="grid grid-cols-5">
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  Payment Mode
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">Range</p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  Base Rates
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  My Commission
                </p>
              </div>
              <div>
                <p className=" text-[#777777] text-xs font-normal">
                  Final Rates
                </p>
              </div>
            </div>
            {paymentModes?.map((mode: any, i: any) => {
              return (
                <SchoolPaymentMode
                  mode={mode}
                  key={i}
                  edit={edit}
                  indexOfPaymetMode={i}
                  setEdit={setEdit}
                  paymentModes={paymentModes}
                  setPaymentModes={setPaymentModes}
                  //handleCommissionChange={handleChargeChange}
                  handleFinalRatesChange={handleFinalRatesChange}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
}

export default RateTab;
