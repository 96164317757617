import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Form from "../../../../components/Form/Form";
import Input from "../../../../components/Input/Input";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { UPDATE_PG_SCHOOL_MUTATION } from "./Querries";
import Papa from "papaparse";

const SchoolsUpdateViaCsv: React.FC<{ refetchPgSchools: () => void }> = ({
  refetchPgSchools,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [csvSchoolsList, setCsvSchoolsList] = useState<any[]>([]);
  const [UpdateSchoolsViaCsv] = useMutation(UPDATE_PG_SCHOOL_MUTATION);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      toast.error("File not selected!");
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const extractedData: any[] = results.data as any[];

        // Filter out entries with null Merchant ID
        const filteredData = extractedData.filter(
          (entry) => entry["Merchant ID"] !== null
        );

        if (filteredData.length === 0) {
          toast.error("No valid data found in the CSV file!");
          return;
        }

        const mappedData = filteredData.map((row: any) => ({
          "Merchant ID": row["Merchant ID"],
          "Merchant Name": row["Merchant Name"],
          "Merchant Email": row["Merchant Email"],
          "Merchant Status": row["Merchant Status"],
          "PG Min KYC": row["PG Min KYC"],
          "PG Full KYC": row["PG Full KYC"],
        }));
        console.log(mappedData);

        setCsvSchoolsList(mappedData);
        setIsButtonActive(true);
      },
      error: (error) => {
        console.error("CSV parsing error:", error);
        toast.error("Error parsing CSV file!");
      },
    });
  };

  const updateSchoolsViaCsv = async (data: any[]) => {
    const mappedData = data.map((item) => ({
      merchantId: item["Merchant ID"],
      merchantName: item["Merchant Name"],
      merchantEmail: item["Merchant Email"],
      merchantStatus: item["Merchant Status"],
      minKycStatus: item["PG Min KYC"],
      fullKycStatus: item["PG Full KYC"],
    }));

    try {
      const { data } = await UpdateSchoolsViaCsv({
        variables: { body: mappedData },
      });
      toast.success(data?.update_pg_school);
      refetchPgSchools();
    } catch (error) {
      toast.error("Error updating schools through csv");
    }
  };
  // const sampleFileDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = `${process.env.PUBLIC_URL}/sample-file.csv`;
  //   link.download = "sample.csv";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <div>
      <button
        className="m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5 float-right"
        onClick={() => setOpen(true)}
      >
        Upload School Data
      </button>
      <Modal
        title="Upload CSV File ( Bulk Data )"
        open={open}
        setOpen={setOpen}
      >
        <div className="flex w-full">
          <div className="mx-auto">
            <Form
              onSubmit={async (data: any) => {
                await updateSchoolsViaCsv(csvSchoolsList);
                setOpen(false);
              }}
              className=""
            >
              <Input
                add_error={() => {}}
                type="file"
                name="Upload CSV File"
                onChange={(e: any, v: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileUpload(v);
                }}
                label="CSV File"
              />

              <a
                className=" text-left text-sm hover:text-[#6F6AF8] max-w-[180px] my-2"
                download={"sample.csv"}
                href={`${process.env.PUBLIC_URL}/sample-file.csv`}
              >
                Download Sample File
              </a>

              <button
                type="submit"
                className="m-2 mt-6 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5"
                disabled={!isButtonActive}
              >
                Upload
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SchoolsUpdateViaCsv;
