import React, { useEffect } from "react";
import { useState } from "react";
import BackButton from "../../../../components/BackButton/BackButton";
import Select from "react-select";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ADD_PLATFORM_CHARGE,
  DELETE_PLATFORM_CHARGE,
  GET_ALL_TRUSTEE,
  GET_ALL_TRUSTEE_SCHOOL,
  UPDATE_PLATFORM_CHARGE,
} from "./Queries";
import Modal from "../../../../components/Modal/Modal";
import KmRateCard, { RangeCard } from "./components/Range";
import { toast } from "react-toastify";
import { netbankingBanks } from "./data/bankData";
import SetPlatformChargesComponent from "./components/SetPlatformChargesComponent";
import EditOptions from "./components/EditOptions";
import BulkMDR from "./components/BulkMDR";

export const platform_type = [
  {
    label: "UPI",
    value: [{ label: "Others", value: "Others" }],
  },
  {
    label: "CreditCard",
    value: [
      { label: "Visa", value: "Visa" },
      { label: "MasterCard", value: "MasterCard" },
      { label: "Rupay", value: "Rupay" },
      { label: "Amex", value: "Amex" },
      { label: "Others", value: "Others" },
    ],
  },
  {
    label: "DebitCard",
    value: [
      { label: "Visa", value: "Visa" },
      { label: "MasterCard", value: "MasterCard" },
      { label: "Rupay", value: "Rupay" },
      { label: "Maestro", value: "Maestro" },
      { label: "Others", value: "Others" },
    ],
  },
  {
    label: "NetBanking",
    value: [...netbankingBanks, { label: "Others", value: "Others" }],
  },
  {
    label: "Wallet",
    value: [
      { label: "Paytm", value: "paytm" },
      { label: "PhonePe", value: "PhonePe" },
      { label: "Ola", value: "Ola" },
      { label: "Amazon", value: "Amazon" },
      { label: "Freecharge", value: "Freecharge" },
      { label: "Jio", value: "Jio" },
      { label: "Airtel", value: "Airtel" },
      { label: "MobikWik", value: "MobikWik" },
      { label: "Others", value: "Others" },
    ],
  },
  {
    label: "PayLater",
    value: [
      { label: "epayLater", value: "epayLater" },
      { label: "LazyPay", value: "LazyPay" },
      { label: "OlaPostpaid", value: "OlaPostpaid" },
      { label: "Simpl", value: "Simpl" },
      { label: "FlexiPay", value: "FlexiPay" },
      { label: "Kotak", value: "Kotak" },
      { label: "ZestMoney", value: "ZestMoney" },
      { label: "OlaMoney", value: "OlaMoney" },
      { label: "Others", value: "Others" },
    ],
  },
  {
    label: "CardLess EMI",
    value: [
      { label: "ZestMoney", value: "ZestMoney" },
      { label: "InstaCred", value: "InstaCred" },
      { label: "FlexMoney", value: "FlexMoney" },
      { label: "Others", value: "Others" },
    ],
  },
  {
    label: "UPI",
    value: [{ label: "Others", value: "Others" }],
  },
  {
    label: "CORPORATE CARDS",
    value: [{ label: "Others", value: "Others" }],
  },
  {
    label: "INTERNATIONAL PG",
    value: [{ label: "Others", value: "Others" }],
  },
];

function ChargeDetail({
  item,
  deletePlatformCharge,
  trusteeSchoolId,
  setSelectTrusteeSchool,
  selectTrusteeSchool,
  setTrusteeSchools,
  truseteeSchools,
  setViewCharges,
  setMdr,
}: any) {
  const [showToggleOption, setShowToggleOption] = useState(false);

  return (
    <div
      onMouseLeave={() => setShowToggleOption(false)}
      className="grid grid-cols-3 text-center grid-body items-center p-3 my-2 odd:bg-[#ECEDFB] even:bg-white odd:border-none even:border border-[#ECEDFB] rounded-lg"
    >
      <div>{item.platform_type}</div>
      <div>{item.payment_mode}</div>
      <div className="relative">
        <i
          className="fa-solid fa-ellipsis-vertical ml-auto cursor-pointer p-2"
          onClick={() => {
            setShowToggleOption(true);
          }}
        ></i>

        {showToggleOption && (
          <EditOptions
            deletePlatformCharge={deletePlatformCharge}
            trusteeSchoolId={trusteeSchoolId}
            platform_type={item.platform_type}
            payment_mode={item.payment_mode}
            setSelectTrusteeSchool={setSelectTrusteeSchool}
            selectTrusteeSchool={selectTrusteeSchool}
            setTrusteeSchools={setTrusteeSchools}
            truseteeSchools={truseteeSchools}
            setViewCharges={setViewCharges}
            setMdr={setMdr}
            range_charges={item.range_charge}
          />
        )}
      </div>
    </div>
  );
}

const ViewRangeChargesAndUpdate = ({
  trusteeSchoolId,
  mdr,
  truseteeSchools,
  setTrusteeSchools,
  selectTrusteeSchool,
  setSelectTrusteeSchool,
}: any) => {
  let breakPoint: any = [];
  let fees: any = [];
  let card: any = [];
  let type: any = [];

  mdr.range_charges.map((charge: any, index: number) => {
    if (charge.upto) breakPoint.push(charge.upto);
    fees.push(charge.charge);
    card.push(index + 1);
    type.push(charge.charge_type);
  });

  const [updatePlatformCharge, { loading, error }] = useMutation(
    UPDATE_PLATFORM_CHARGE
  );

  const updateMDR = async (platformCharge: any) => {
    const { data } = await updatePlatformCharge({
      variables: {
        trusteeSchoolId: trusteeSchoolId,
        platform_type: mdr.platform_type,
        payment_mode: mdr.payment_mode,
        range_charge: platformCharge,
      },
    });

    truseteeSchools.map((truseteeSchool: any) => {
      if (truseteeSchool.value._id === trusteeSchoolId) {
        truseteeSchool.value.platform_charges = data.updatePlatformCharge;
      }
    });

    selectTrusteeSchool.value.platform_charges = data.updatePlatformCharge;

    setSelectTrusteeSchool(selectTrusteeSchool);
    setTrusteeSchools(truseteeSchools);

    toast.success("MDR successfully updated");
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return (
    <KmRateCard
      breakPoint_data={breakPoint}
      fees_data={fees}
      card_data={card}
      amountType_data={type}
      updateMDR={updateMDR}
    />
  );
};

const ManagePlatformCharges = () => {
  const [paymentModes, setPayementModes] = useState<any>(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>(null);

  const [AddPlatformCharge, { loading, error }] =
    useMutation(ADD_PLATFORM_CHARGE);
  const [
    deletePlatformCharge,
    {
      loading: deletePlatformCharge_loading,
      error: deletePlatformCharge_error,
    },
  ] = useMutation(DELETE_PLATFORM_CHARGE);

  useEffect(() => {
    toast.error(deletePlatformCharge_error?.message);
  }, [deletePlatformCharge_error]);

  const setPlatformCharges = async (range_charge: any) => {
    if (!paymentModes) {
      toast.error("Payment type required");
      return;
    }
    if (!selectedPaymentMode) {
      toast.error("Payment mode required");
      return;
    }

    const { data } = await AddPlatformCharge({
      variables: {
        trusteeSchoolId: selectTrusteeSchool.value._id,
        platform_type: paymentModes.label,
        payment_mode: selectedPaymentMode.value,
        range_charge,
      },
    });

    truseteeSchools?.map((truseteeSchool: any) => {
      if (truseteeSchool.value._id === selectTrusteeSchool.value._id) {
        truseteeSchool.value.platform_charges = data.AddPlatformCharge;
      }
    });

    selectTrusteeSchool.value.platform_charges = data.AddPlatformCharge;

    setSelectTrusteeSchool(selectTrusteeSchool);
    setTrusteeSchools(truseteeSchools);

    toast.success("Successfully Added");
  };

  const [trustee, setTrustee] = useState([]);
  const [truseteeSchools, setTrusteeSchools] = useState<any>([]);
  const [selectedTrustee, setSelectedTrustee] = useState<any>(null);
  const [selectTrusteeSchool, setSelectTrusteeSchool] = useState<any>(null);

  const {
    data: trustee_data,
    loading: trustee_loading,
    error: trustee_error,
  } = useQuery(GET_ALL_TRUSTEE);
  const [
    getTrusteeSchools,
    {
      loading: trustee_school_loading,
      error: trustee_school_error,
      data: trustee_school_data,
    },
  ] = useLazyQuery(GET_ALL_TRUSTEE_SCHOOL);

  useEffect(() => {
    if (trustee_data && trustee_data.getAllTrusteeInSinglePage) {
      let temp: any = [];
      trustee_data.getAllTrusteeInSinglePage.map((curr_trustee: any) => {
        temp.push({ value: curr_trustee._id, label: curr_trustee.name });
      });
      setTrustee(temp);
    }
  }, [trustee_data]);

  useEffect(() => {
    if (trustee_school_data && trustee_school_data.getAllTrusteeSchool) {
      let temp: any = [];
      trustee_school_data.getAllTrusteeSchool.map(
        (curr_trustee_school: any) => {
          temp.push({
            value: {
              _id: curr_trustee_school._id,
              platform_charges: curr_trustee_school.platform_charges,
            },
            label: curr_trustee_school.school_name,
          });
        }
      );
      setTrusteeSchools(temp);
    }
  }, [trustee_school_data]);

  useEffect(() => {
    toast.error(error?.message);
  }, [error]);

  useEffect(() => {
    if (!selectedTrustee) return;
    setSelectTrusteeSchool(null);

    getTrusteeSchools({
      variables: {
        trusteeId: selectedTrustee.value,
      },
    });
  }, [selectedTrustee]);

  //Popup handling
  const [addCharge, setAddCharge] = useState(false);
  const [viewCharges, setViewCharges] = useState(false);
  const [mdr, setMdr] = useState(null);
  const [bulkMDR, setBulkMDR] = useState(false);

  return (
    <>
      <Modal
        open={addCharge}
        setOpen={setAddCharge}
        title="Set Platform Charges"
        className="max-w-3xl"
      >
        <SetPlatformChargesComponent
          setPayementModes={setPayementModes}
          paymentModes={paymentModes}
          selectedPaymentMode={selectedPaymentMode}
          setSelectedPaymentMode={setSelectedPaymentMode}
          setPlatformCharges={setPlatformCharges}
        />
      </Modal>

      <Modal
        open={viewCharges}
        setOpen={setViewCharges}
        title="View Charges"
        className="max-w-3xl"
      >
        <ViewRangeChargesAndUpdate
          trusteeSchoolId={selectTrusteeSchool?.value._id}
          mdr={mdr}
          truseteeSchools={truseteeSchools}
          setTrusteeSchools={setTrusteeSchools}
          selectTrusteeSchool={selectTrusteeSchool}
          setSelectTrusteeSchool={setSelectTrusteeSchool}
        />
      </Modal>

      <Modal
        open={bulkMDR}
        setOpen={setBulkMDR}
        title="Upload Excel file for MDR"
        className="max-w-3xl"
      >
        <BulkMDR trusteeSchoolId={selectTrusteeSchool?.value._id} />
      </Modal>

      <BackButton label="Manage platform charges" />

      <div className="flex justify-end">
        <Select
          value={selectedTrustee}
          onChange={(selectedOption: any) => {
            setSelectedTrustee(selectedOption);
          }}
          options={trustee}
          styles={{
            container: (provided: any, state: any) => ({
              ...provided,
              width: "200px",
              marginRight: "10px",
            }),
          }}
          placeholder="Select Trustee"
        />

        <Select
          value={selectTrusteeSchool}
          onChange={(selectedOption: any) => {
            setSelectTrusteeSchool(selectedOption);
          }}
          options={truseteeSchools}
          styles={{
            container: (provided: any, state: any) => ({
              ...provided,
              width: "200px",
              marginRight: "10px",
            }),
          }}
          placeholder="Select School"
          isDisabled={!(selectedTrustee && truseteeSchools)}
        />

        <button
          className="px-4 py-1 bg-[#6F6AF8] rounded-md text-white"
          disabled={!(selectedTrustee && selectTrusteeSchool)}
          onClick={() => setAddCharge(true)}
        >
          Set MDR
        </button>

        <button
          className="px-4 py-1 bg-[#6F6AF8] rounded-md text-white ml-[5px]"
          disabled={!(selectedTrustee && selectTrusteeSchool)}
          onClick={() => setBulkMDR(true)}
        >
          Bulk MDR
        </button>
      </div>

      <div className="w-full">
        <div>
          <div className="w-full">
            <div className="table w-full shadow p-8 rounded-lg my-2 text-[#1e1b59]">
              <div className="grid grid-cols-3 grid-header p-3 font-semibold  bg-[#ECEDFB] rounded-t-lg text-violet-900">
                <div className="text-center">Platfrom Type</div>
                <div className="text-center">Payment Mode</div>
                <div className="text-center">Action</div>
              </div>
              {selectTrusteeSchool?.value?.platform_charges.map(
                (item: any, i: number) => (
                  <ChargeDetail
                    item={item}
                    deletePlatformCharge={deletePlatformCharge}
                    trusteeSchoolId={selectTrusteeSchool.value._id}
                    truseteeSchools={truseteeSchools}
                    setTrusteeSchools={setTrusteeSchools}
                    selectTrusteeSchool={selectTrusteeSchool}
                    setSelectTrusteeSchool={setSelectTrusteeSchool}
                    setViewCharges={setViewCharges}
                    setMdr={setMdr}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePlatformCharges;
