import React, { useState } from "react";
import Table from "../../../../components/Table/Table";
import BackButton from "../../../../components/BackButton/BackButton";
import { useQuery } from "@apollo/client";
import { GET_TRUSTEES } from "../ManageTrustees/Querries";
import { Link, Route, Routes } from "react-router-dom";
import SchoolCard from "../School/pages/SchoolsCard";
import SinglesSchoolCard from "../School/pages/SinglesSchoolCard";
import TrusteeList from "./components/TrusteeList";
import SingleTrusteeSchools from "./components/SingleTrusteeSchools";

function ManageMdr() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 5;

  const {
    data: trusteesData,
    loading: trusteesLoading,
    error: trusteesError,
    refetch,
  } = useQuery(GET_TRUSTEES, {
    variables: {
      page: currentPage,
      pageSize: pageSize,
    },
  });
  return (
    <div>
      <Routes>
        <Route path="/" element={<TrusteeList />} />
        <Route path="/:id/*" element={<SingleTrusteeSchools />} />
      </Routes>
    </div>
  );
}

export default ManageMdr;
