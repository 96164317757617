import { gql } from "@apollo/client";


export const ASSIGN_ONBORDER_TO_TRUSTEE = gql`
    mutation assignOnboarderToTrustee(
        $erp_id: String!
        $onboarder_id: String!
    ){
        assignOnboarderToTrustee(
            erp_id: $erp_id,
            onboarder_id: $onboarder_id
        ){
            _id
        }
    }
`