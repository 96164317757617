import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../components/BackButton/BackButton";
import Table from "../../../../../components/Table/Table";
import { GET_TRUSTEES } from "../../ManageTrustees/Querries";
import { HiOutlineDotsVertical } from "react-icons/hi";
import RateChargeModal from "../../../../../components/Modal/RateChargeModal";
import SetPlatformChargesComponent from "../../ManagePlatformCharges/components/SetPlatformChargesComponent";
import { SET_TRUSTEE_BASE_MDR } from "../Queries";
import { toast } from "react-toastify";
import SetGatwayRate from "../../ManageGatways/SetGatwayRate";
const Action = ({
  setOpenRateChargeModal,
  setTrusteeId,
  trustee_id,
  setOpenSetGatwayModal,
}: any) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      className="relative cursor-pointer pl-3"
      onClick={() => {
        setShowOptions(!showOptions);
        setTrusteeId(trustee_id);
      }}
    >
      <i className="fa-solid fa-ellipsis-vertical"></i>

      {showOptions && (
        <div
          className={`z-50 flex  flex-col fixed right-[220px]`}
          onMouseLeave={() => setShowOptions(false)}
        >
          <button
            className={`py-2 bg-white w-[100%] rounded-t-md text-xs text-[#1B163B] hover:bg-slate-100  float-right px-6`}
            onClick={() => {
              setOpenRateChargeModal(true);
            }}
          >
            Set MDR
          </button>
          <button
            className={`py-2 bg-white text-xs text-[#1B163B] hover:bg-slate-100  float-right px-6`}
            onClick={() => {
              setOpenSetGatwayModal(true);
            }}
          >
            Set Gateway Ratio
          </button>
        </div>
      )}
    </div>
  );
};

function TrusteeList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [openRateChargeModal, setOpenRateChargeModal] = useState(false);
  const [paymentModes, setPayementModes] = useState<any>(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>(null);
  const [trusteeId, setTrusteeId] = useState("");
  const [openSetGatwayModal, setOpenSetGatwayModal] = useState(false);
  const [setBaseMdr] = useMutation(SET_TRUSTEE_BASE_MDR);
  const pageSize = 5;

  const {
    data: trusteesData,
    loading: trusteesLoading,
    error: trusteesError,
    refetch,
  } = useQuery(GET_TRUSTEES, {
    variables: {
      page: currentPage,
      pageSize: pageSize,
    },
  });
  const trustees = trusteesData?.getAllTrustee?.data || [];
  const totalPages = trusteesData?.getAllTrustee?.totalPages || 1;
  const setPlatformCharges = async (range: any) => {
    try {
      const res = await setBaseMdr({
        variables: {
          comment: "NA",
          platform_charges: {
            range_charge: range,
            platform_type: paymentModes?.label,
            payment_mode: selectedPaymentMode?.label,
          },
          trustee_id: trusteeId,
        },
      });
      setOpenRateChargeModal(false);
      toast.success(`${res.data.setBaseMdr}`);
    } catch (err) {
      toast.error(`${err}`);
      setOpenRateChargeModal(false);
    }
  };

  return (
    <div>
      <BackButton label="Manage Trustee MDR" />
      <RateChargeModal
        open={openSetGatwayModal}
        setOpen={setOpenSetGatwayModal}
      >
        <SetGatwayRate trusteeId={trusteeId} setOpen={setOpenSetGatwayModal} />
      </RateChargeModal>
      <RateChargeModal
        open={openRateChargeModal}
        setOpen={setOpenRateChargeModal}
      >
        <div className="p-8 bg-white h-full">
          <p className=" text-edviron_black text-lg mb-4 font-medium">
            Set Base MDR
          </p>
          <SetPlatformChargesComponent
            setPayementModes={setPayementModes}
            paymentModes={paymentModes}
            selectedPaymentMode={selectedPaymentMode}
            setSelectedPaymentMode={setSelectedPaymentMode}
            setPlatformCharges={setPlatformCharges}
          />
        </div>
      </RateChargeModal>
      {trustees ? (
        <>
          <Table
            data={[
              ["Name", "Phone Number", "Email ID", "School Limits", "Action"],
              ...trustees.map((d: any, i: number) => [
                <Link
                  to={`${d._id}`}
                  className="text-center text-violet-500 cursor-pointer"
                >
                  {d.name}
                </Link>,
                <div className="text-center">{d.phone_number}</div>,
                <div className="text-center">{d.email_id}</div>,
                <div>{d.school_limit}</div>,
                <Action
                  setOpenRateChargeModal={setOpenRateChargeModal}
                  trustee_id={d._id}
                  setTrusteeId={setTrusteeId}
                  setOpenSetGatwayModal={setOpenSetGatwayModal}
                />,
                // <div className="text-center">
                //   <HiOutlineDotsVertical
                //     onClick={() => {
                //       setTrusteeId(d._id);
                //       //setOpenRateChargeModal(true);
                //     }}
                //     className="mx-auto cursor-pointer"
                //   />
                // </div>,
              ]),
            ]}
          />

          <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
              onClick={async () => {
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
                await refetch({
                  page: currentPage > 1 ? currentPage - 1 : 1,
                  pageSize: pageSize,
                });
              }}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 rounded-md text-gray-500 text-lg font-bold">
              {" "}
              {currentPage}/{totalPages}{" "}
            </span>
            <button
              className="ml-2 px-4 py-2 bg-[#6F6AF8] rounded-md text-white"
              onClick={async () => {
                setCurrentPage(currentPage + 1);
                await refetch({
                  page: currentPage + 1,
                  pageSize: pageSize,
                });
              }}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TrusteeList;
